<template>
  <div>
    <ParallaxImageTitle :imagePath="require('@/assets/images/www/programs/recreational-pilot-licence-800.png')" title="RECREATIONAL PILOT LICENCE" subtitle="The first CASA licence" />
    <div class="program-description">
      <h1>What is a Recreational Pilot Licence (RPL)?</h1>
      <p>
        The Recreational Pilot Licence allows you to fly a light single engine aircraft as the Pilot in Command without supervision within 25nm of your departure point.
      </p>

      <br>

      <h1>Requirements</h1>
      <div class="list-container">
        <ul class="program-list">
          <li><b>Flight time as pilot:</b> 25 hours</li>
          <li><b>Solo flight time:</b> 5 hours</li>
          <li><b>Dual instrument time:</b> 2 hours</li>
          <li><b>Medical standard:</b> Class 1 or 2</li>
          <li><b>Theory &amp; Exams</b>
            <ul class="program-list">
              <li>RPL Theory Exam</li>
              <li>English Language Assessment</li>
            </ul>
          </li>
        </ul>
      </div>

      <br>

      <p>
        <b>Note:</b> If you have a RPC you can convert across to a RPL by conducting a flight review. You can add navigation and controlled airspace endorsements to this licence.
      </p>
    </div>
  </div>
</template>

<script>
import ParallaxImageTitle from '@/components/ParallaxImageTitle.vue'

export default {
  components: {
    ParallaxImageTitle
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/_presets.scss';
@include program-page;
</style>
